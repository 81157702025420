import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Property = ({ property }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleNextImage = (e) => {
    e.stopPropagation(); // Prevent clicking on the arrow from triggering the link
    setCurrentImageIndex((currentImageIndex + 1) % property.images.length);
  };

  const handlePrevImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((currentImageIndex - 1 + property.images.length) % property.images.length);
  };

  return (
    <div className="property">
      <div className="property-image-container">
        <img src={property.images[currentImageIndex]} alt={property.title} />
        <div className="arrow arrow-left" onClick={handlePrevImage}>&#9664;</div>
        <div className="arrow arrow-right" onClick={handleNextImage}>&#9654;</div>
      </div>
      <Link to={`/properties/${property.id}`}>
        <h2>{property.title}</h2>
      </Link>
      <p>{property.description}</p>
    </div>
  );
};

export default Property;

