import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './PropertyPage.css';

function PropertyPage({ properties }) {
  const { id } = useParams();
  const property = properties.find(prop => prop.id === parseInt(id));

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!property) {
    return <p>Property not found</p>;
  }

  const { title, description, images } = property;

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="property-page">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="property-page-slider">
        <div className="image-container">
          <button className="arrow-button left" onClick={prevImage}>
            &lt;
          </button>
          <img
            src={process.env.PUBLIC_URL + images[currentImageIndex]}
            alt={`${title} ${currentImageIndex + 1}`}
            className="property-page-image"
          />
          <button className="arrow-button right" onClick={nextImage}>
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}

export default PropertyPage;

