import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Property from './Property';
import PropertyPage from './PropertyPage';
import logo from './logo.jpeg';
import propertiesData from './properties.json';

function App() {
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    type: '',
    location: '',
    priceRange: '',
    bedrooms: '',
    bathrooms: ''
  });

  useEffect(() => {
    setProperties(propertiesData);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    const newFilters = {
      ...filters,
      [event.target.name]: event.target.value
    };
    setFilters(newFilters);
  };

  const resetFilters = () => {
    setFilters({
      type: '',
      location: '',
      priceRange: '',
      bedrooms: '',
      bathrooms: ''
    });
    setSearchTerm('');
  };

  const filteredProperties = properties.filter(property =>
    (property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (filters.type === '' || property.type === filters.type) &&
    (filters.location === '' || property.location === filters.location) &&
    (filters.priceRange === '' || property.priceRange === filters.priceRange) &&
    (filters.bedrooms === '' || property.bedrooms.toString() === filters.bedrooms) &&
    (filters.bathrooms === '' || property.bathrooms.toString() === filters.bathrooms)
  );

  return (
    <Router>
      <div className="App">
        <header>
          <div className="container">
            <div className="header-content">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Logo" />
                </Link>
              </div>
              <div className="title">
                <Link to="/" className="title-link">MAGG Propiedades</Link>
              </div>
              <div className="contact-info">
                <p>Arq. Angeles García</p>
                <p>maggpropiedades37@gmail.com</p>
                <p>Phone: +52 55 26 90 72 03</p>
                <a
                  href="https://wa.me/525526907203?text=Buenos%20d%C3%ADas,%20me%20gustar%C3%ADa%20recibir%20m%C3%A1s%20informaci%C3%B3n%20sobre%20ciertas%20propiedades%20de%20su%20p%C3%A1gina%20web"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whatsapp-button"
                >
                  WhatsApp
                </a>
              </div>
            </div>
          </div>
        </header>
        <Routes>
          <Route path="/" element={
            <>
              <section className="search-bar">
                <div className="container">
                  <input 
                    type="text" 
                    placeholder="Buscar propiedades..." 
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </section>
              <section className="filters-bar">
                <div className="container">
                  <select name="type" value={filters.type} onChange={handleFilterChange}>
                    <option value="">Compra/Renta</option>
                    <option value="Compra">Compra</option>
                    <option value="Renta">Renta</option>
                  </select>
                  <select name="location" value={filters.location} onChange={handleFilterChange}>
                    <option value="">Colonia/Municipio</option>
                    <option value="New York ">CDMX Tlalpan</option>
                    <option value="California">CDMX San Angel</option>
                  </select>
                  <select name="priceRange" value={filters.priceRange} onChange={handleFilterChange}>
                    <option value="">Rango Precio</option>
                    <option value="500k-1M"> Menos de 5000000 </option>
                    <option value="1M-2M">5000000-7000000</option>
                    <option value="1M-2M"> Más de 7000000</option>
                  </select>
                  <select name="bedrooms" value={filters.bedrooms} onChange={handleFilterChange}>
                    <option value="">Recámaras</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                  <select name="bathrooms" value={filters.bathrooms} onChange={handleFilterChange}>
                    <option value="">Baños</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <button onClick={resetFilters}>Borrar Búsqueda</button>
                </div>
              </section>
              <section className="property-listings">
                <div className="container property-grid">
                  {filteredProperties.length > 0 ? (
                    filteredProperties.map(property => (
                      <Property key={property.id} property={property} />
                    ))
                  ) : (
                    <p>No listings fitting your criteria.</p>
                  )}
                </div>
              </section>
            </>
          } />
          <Route path="/properties/:id" element={<PropertyPage properties={properties} />} />
        </Routes>
        <footer>
          <div className="container">
            <p>&copy; 2024 Real Estate Website. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;

